.programs__container {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}

.programs h3 {
    margin-bottom: 0.8rem;
}

.programs small {
    margin-bottom: 2rem;
}

.programs h1 {
    color: var(--color-primary-variant);
    display: inline;
}

.programs h2 {
    display: inline;
    color: var(--color-gray-400);
}

.pprogramslan h4 {
    margin-top: 2rem;
}

.programs p {
    margin-top: 1rem;
    color: var(--color-gray-100)
}

.programs p.disabled {
    color: var(--color-gray-400);
}

.programs button {
    margin-top: 3rem;
    cursor: pointer;
}








/* MEDIA QUERIES (medium screens) */
@media screen and (max-width: 1024px) {
    .plans__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}


/* MEDIA QUERIES (small screens) */
@media screen and (max-width: 600px) {
    .plans__container {
        grid-template-columns: 1fr;
    }

    .plan {
        width: 90%;
        margin-inline: auto;
    }
}