.plans__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}

.plan h3 {
  font-size: 1.8rem;
  margin-bottom: 0.8rem;
}


.plan small {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.plan h1 {
  color: var(--color-primary-variant);
  display: inline;
  font-size: 1.6rem;
}

.plan h2 {
  display: inline;
  color: var(--color-gray-400);
}

.plan h4 {
  margin: 2rem 0;
}

.plan p {
  margin-top: 1rem;
  font-size: 1.2rem;
  color: var(--color-gray-100);
}

.plan p.disabled {
  color: var(--color-gray-400);
}

.plan button {
  margin-top: 3rem;
  cursor: pointer;
}

/* MEDIA QUERIES (medium screens) */
@media screen and (max-width: 1024px) {
  .plans__container {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}

/* MEDIA QUERIES (small screens) */
@media screen and (max-width: 600px) {
  .plans__container {
    grid-template-columns: 1fr;
  }

  .plan {
    width: 90%;
    margin-inline: auto;
  }
}
