.about__section-image {
  overflow: hidden;
}

.about__section-content h1 {
  margin-bottom: 2rem;
}

.about__section-content p {
  margin-bottom: 1rem;
}

.about__story-container,
.about__mission-container {
  display: grid;
  grid-template-columns: 40% 50%;
  gap: 10%;
}

.about__vision-container {
  display: grid;
  grid-template-columns: 50% 40%;
  gap: 10%;
}

.about__story,
.about__Vision,
.about__mission {
  font-size: 1.5rem;
}

.main__header-circle ,.main__header-image{
  width: 30rem;
  height: 30rem;
}

.about__story img,
.about__vision img {
  width: auto;
  height: 360px;
}

.about__mission img {
  width: auto;
  height: 450px;
}

.about__mission .main__header-image {
    width: 20rem;
    margin: auto;
}

/* MEDIA QUERIES (medium screens) */
@media screen and (max-width: 1024px) {
  .about__section-image {
    width: 60%;
  }

  .about__section-content h1 {
    margin-bottom: 1.2rem;
  }

  .about__story-container,
  .about__vision-container,
  .about__mission-container {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .about__vision .about__section-image {
    grid-row: 1;
  }
}

/* MEDIA QUERIES (small screens) */
@media screen and (max-width: 600px) {
  .about__section-image {
    width: 80%;
    margin-inline: auto;
  }

  .about__story-container,
  .about__vision-container,
  .about__mission-container {
    gap: 2rem;
  }
}
