.contact__container {
  display: grid;
  place-items: center;
}

.contact__wrapper {
  width: fit-content;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  width: 50%;
}

.contact__wrapper a {
  background: var(--color-primary);
  font-size: 2rem;
  border: 2px solid transparent;
  transition: var(--transition);
  padding: 2rem;
  border-radius: 1.5rem;
  margin-right: 10px;
}

.contact__wrapper a:hover {
  background: transparent;
  border-color: var(--color-gray-100);
}

.map-container {
    gap: 30px;
    width: 100%;
 display: flex;

}
a.mero-link{
    font-size: 1rem;
    margin-bottom: 20px;
}
.map{
    width: 50%;
 }

/* MEDIA QUERIES (small screens) */
@media screen and (max-width: 600px) {
  .contact__wrapper {
    gap: 1.5rem;
  }

  .contact__wrapper a {
    padding: 1rem;
    font-size: 1.2rem;
    border-radius: 1rem;
  }
}
